import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from '../Navbar/index';
import UserContext from "../../UserContext";
import { isLoggedIn } from "../../utils";
import LoginPrompt from "../LoginPrompt";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LeftPane from "../LeftPane/LeftPane";
import { useMediaQuery, createTheme, Box, Button, Card, CardContent, Typography } from '@mui/material';
import ReactGA from "react-ga4";
import Footer from '../Footer/Footer';
import OnScreenMenu from '../OnScreenMenu';
import {Circles} from "react-loader-spinner";
import {newsData} from './news'
import './index.css';

const SummaryBusinessDashboard = () => {
  // const navigate = useNavigate();
  const [ setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails({
        sub: userData.sub,
        name: userData.name,
        email: userData.email,
        picture: userData.picture,
        plan: userData.plan,
        premium: userData.premium,
        user_id: userData.user_id,
      });
      // setLoggedInUserDetails(userData);
    }
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });



  return (
    <>
      {isMobile ? (
        <LeftPane Component={SummaryBusiness} />
      ) : (
        <>
          <Navbar />
          <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <div style={{ marginTop: '78px' }}>
              <SummaryBusiness />
            </div>
          </Box>
        </>
      )}
    </>
  );
};

const SummaryBusiness = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");
  const [pyqData, setPyqData] = useState(null);
  const [flashcardData, setFlashcardData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingPyq, setLoadingPyq] = useState(false);
  const [loadingFlashcard, setLoadingFlashcard] = useState(false);
  const [error, setError] = useState(null);
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [flashCardId,setFlashCardId]=useState('')
  const [currentPrelimsIndex, setCurrentPrelimsIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dataFromParams, setDataFromParams] = useState(false);



  const handleNext = () => {
    if (currentPrelimsIndex < pyqData.prelims.length - 1) {
      setCurrentPrelimsIndex(currentPrelimsIndex + 1);
      setSelectedOption(null);
    }
  };



  const handlePrevious = () => {
    if (currentPrelimsIndex > 0) {
      setCurrentPrelimsIndex(currentPrelimsIndex - 1);
      setSelectedOption(null);
    }
  };

  const handleOptionClick = (index) => {
    setSelectedOption(index);
  };
   const currentQuestion = pyqData && pyqData.prelims[currentPrelimsIndex];


  const fetchData = async () => {
    // if (!loggedInUserDetails.user_id) {
      // setOpenLoginPrompt(true);
      // return;
    // }

    setLoadingSummary(true);
    setLoadingPyq(true);
    setLoadingFlashcard(true);
    setError(null);

    try {
      const summaryResponse = await axios.post('https://collectorbabu.com/api/business/summary', {
        // user_id: loggedInUserDetails.user_id,
        user_id: "659662fb004103e6fd40aac4",

        content: content,
      });

      setSummaryData(summaryResponse.data);

      const summaryDataNotes = summaryResponse.data.summary_notes;

      const pyqResponse = await axios.post('https://collectorbabu.com/api/business/pyq', {
        // user_id: loggedInUserDetails.user_id,
        user_id:"659662fb004103e6fd40aac4",

        content: summaryDataNotes,
      });

      setPyqData(pyqResponse.data);
      setCurrentPrelimsIndex(0);


      const flashcardResponse = await axios.post('https://collectorbabu.com/api/business/flashcards', {
        user_id: "659662fb004103e6fd40aac4",
        content: content,
      });
      setFlashcardData(flashcardResponse.data.flashcards);
      setCurrentCardIndex(0);


    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoadingSummary(false);
      setLoadingPyq(false);
      setLoadingFlashcard(false);
    }
  };

  useEffect(() => {
      if (id) {
      setContent(newsData[id]);
      setDataFromParams(true);
      }
  }, [id]);

  useEffect(() => {
    if (content) {
      const timer = setTimeout(() => {
        fetchData();
      }, 2000);
  
      return () => clearTimeout(timer);
    }
  }, [dataFromParams]);
  
  

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  const copyLinkToClipboard = () => {
    const link = `https://collectorbabu.com/flashcards/get_flashcards/${flashCardId}`;
    navigator.clipboard.writeText(link); 
    setCopied(true); 
    setTimeout(() => setCopied(false), 3000); 
  };

  const previousCard = () => {
    setCurrentCardIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setIsFlipped(false);
  };

  const nextCard = () => {
    setCurrentCardIndex((prevIndex) => Math.min(prevIndex + 1, flashcardData.length - 1));
    setIsFlipped(false);
  };

  // const handleLoginPromptOpen = () => {
  //   setOpenLoginPrompt(true);
  // };

  const handleLoginPromptClose = () => {
    setOpenLoginPrompt(false);
  };

  const formatSummaryNotes = (notes) => {
    return notes.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\n/g, '<br />');
  };

  function decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }


  return (
    <>
      <div className="summary-business-container">
        <div className="summary-business-form">
          <textarea
            className="summary-business-textarea"
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <Button className="summary-business-button" variant="contained" color="primary" onClick={fetchData} disabled={loadingSummary || loadingPyq || loadingFlashcard}>
            {loadingSummary || loadingPyq || loadingFlashcard ? 'Loading...' : 'Fetch Data'}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
         {summaryData && (  <div style={{ marginTop: '20px', overflowY: 'auto', height: '500px' }}>
          <Typography variant="h5" gutterBottom>
                  Summary Notes
                </Typography>
          {loadingSummary ? 
          (
            <Circles type="ThreeDots" color="#00BFFF" height={80} width={80} />
          ) : (
            <Card variant="outlined" className="summary-notes-card">
              <CardContent>
             
              <Box className="summary-notes-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatSummaryNotes(summaryData.summary_notes),
                  }}
                />
              </Box>

              </CardContent>
            </Card>
          )}
          </div>
        )}


        {pyqData && (
  <div style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f7f9fc', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
    <Typography variant="h5" gutterBottom style={{ color: '#1976d2', fontWeight: 'bold', textAlign: 'center' }}>
      Prelims Questions
    </Typography>

    <Card variant="outlined" style={{ height: 'auto', border: '2px solid #1976d2', borderRadius: '8px', backgroundColor: '#ffffff', padding: '20px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }} className="flashcard">
      <CardContent>
      {currentQuestion && (
        <>
        <Typography variant="h6" style={{ color: '#1976d2', marginBottom: '10px', textAlign: 'center', fontWeight: '600' }}>
          Year: {currentQuestion.yearAsked}
        </Typography>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(currentQuestion.question).replace(/\n/g, '<br />') }} style={{ marginBottom: '20px', textAlign: 'justify', fontSize: '16px', lineHeight: '1.5' }}></Typography>
        </>
      )}
        
        <div style={{ marginTop: '10px' }}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#555', marginBottom: '10px' }}>Options:</Typography>
          {currentQuestion && (
  <ul>
    {currentQuestion.options.map((option, index) => (
      <li key={index} style={{ marginBottom: '10px' }}>
        <Button
          variant="contained"
          color={selectedOption === index ? (index === currentQuestion.correctChoiceIndex ? 'success' : 'error') : 'primary'}
          onClick={() => handleOptionClick(index)}
          style={{
            marginRight: '10px',
            backgroundColor: selectedOption === index ? (index === currentQuestion.correctChoiceIndex ? '#81c784' : '#e57373') : '#64b5f6',
            color: '#fff',
            transition: 'background-color 0.3s, transform 0.2s',
            padding: '10px 15px',
            borderRadius: '5px',
            width: '100%',
            textTransform: 'none',
            fontWeight: '500',
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
          }}
        >
          {index + 1}. {decodeHTMLEntities(option)}
        </Button>
      </li>
    ))}
  </ul>
)}

        </div>

        {selectedOption !== null && (
          <Typography variant="body2" style={{ marginTop: '10px', fontWeight: 'bold', color: selectedOption === currentQuestion.correctChoiceIndex ? '#4caf50' : '#f44336', textAlign: 'center', fontSize: '16px' }}>
            {selectedOption === currentQuestion.correctChoiceIndex ? 'Correct!' : 'Wrong!'} The correct answer is: {decodeHTMLEntities(currentQuestion.options[currentQuestion.correctChoiceIndex])}
          </Typography>
        )}

        <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <Button
  variant="outlined"
  onClick={handlePrevious}
  disabled={currentPrelimsIndex === 0}
  style={{
    marginRight: '10px',
    borderColor: currentPrelimsIndex === 0 ? '#757575' : '#1976d2',
    color: currentPrelimsIndex === 0 ? '#757575' : '#1976d2',
    borderRadius: '5px',
    textTransform: 'none',
    fontWeight: '500'
  }}
>
  Previous
</Button>

<Button
  variant="outlined"
  onClick={handleNext}
  disabled={currentPrelimsIndex === pyqData.prelims.length - 1}
  style={{
    borderColor: currentPrelimsIndex === pyqData.prelims.length - 1 ? '#757575' : '#1976d2',
    color: currentPrelimsIndex === pyqData.prelims.length - 1 ? '#757575' : '#1976d2',
    borderRadius: '5px',
    textTransform: 'none',
    fontWeight: '500'
  }}
>
  Next
</Button>

        </div>
      </CardContent>
    </Card>

    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
      <Typography variant="h5" gutterBottom style={{ color: '#1976d2', fontWeight: 'bold', textAlign: 'center' }}>
        Mains Questions
      </Typography>
      
      {pyqData.mains && pyqData.mains.map((main, index) => (
        <Card variant="outlined" className="flashcard" key={index} style={{ marginBottom: '20px', border: '2px solid #1976d2', borderRadius: '8px', backgroundColor: '#ffffff', padding: '15px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <CardContent>
            <Typography variant="h6" style={{ color: '#1976d2', marginBottom: '10px', fontWeight: '600' }}>
              Subject: {main.subject} | Year: {main.yearAsked}
            </Typography>
            <Typography variant="body1" style={{ color: '#333', textAlign: 'justify', fontSize: '16px', lineHeight: '1.5' }}>
              {decodeHTMLEntities(main.question)}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  </div>
)}




        { flashcardData && (
              <>
              <h1 style={{ marginTop: '20px' , fontWeight: 'bold', fontSize: '20px', textAlign: 'center'}}>Flashcards</h1>
                <div className="flashcard-container">
                <div className="copy-button-container">
                {/* Copy button */}
                <button className="copy-button" onClick={copyLinkToClipboard}>
                  <FileCopyIcon />
                  {copied && <span className="copied-message">Link copied!</span>}
                </button>
              </div>
                  <h1 className="card-number">Card {currentCardIndex + 1}/{flashcardData.length}</h1>
                  <div className={`flashcard ${isFlipped ? 'flipped' : ''}`} onClick={handleClick}>
                    <div className="front flashcard-front">
                      <h1 className="f-card-heading">Question</h1>
                      {flashcardData[currentCardIndex].prelims_question}
                    </div>
                    <div className="back flashcard-back">
                      <h1 className="f-card-heading">Answer</h1>
                      {Array.isArray(flashcardData[currentCardIndex].knowledge_pointers) ? (
  <ul>
    {flashcardData[currentCardIndex].knowledge_pointers.map((pointer, index) => (
      <li className='f-answer-list-item' key={index}>{pointer}</li>
    ))}
  </ul>
) : typeof flashcardData[currentCardIndex].knowledge_pointers === 'string' ? (
  <ul>
    {flashcardData[currentCardIndex].knowledge_pointers.split('\n').map((pointer, index) => (
      <li className='f-answer-list-item' key={index}>{pointer}</li>
    ))}
  </ul>
) : (
  <p>No knowledge pointers available.</p>
)}

                    </div>

                  </div>
                  <div className="nav">
                    <button onClick={previousCard} disabled={currentCardIndex === 0}>Previous Card</button>
                    <button onClick={nextCard} disabled={currentCardIndex === flashcardData.length - 1}>Next Card</button>
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
      <LoginPrompt
        openLoginPrompt={openLoginPrompt}
        handleLoginPromptClose={handleLoginPromptClose}
      />
      <OnScreenMenu />
      <Footer />
    </>
  );
};

export default SummaryBusinessDashboard;
