import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import { useEffect, useState, useContext } from "react";
import CalendarCircularProgress from '../CalendarCircularProgress/CalendarCircularProgress';
import dayjs from 'dayjs';
import CalendarContext from '../../CalendarContext';

const CalendarStyle = styled.div`
/* ~~~ container styles ~~~ */
  max-width: 350px;
  margin: auto;
  margin-top: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  /* ~~~ navigation styles ~~~ */
  .react-calendar__navigation {
    display: flex;
    .react-calendar__navigation__label {
      font-weight: bold;
    }
    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
    }
  }
  .react-calendar__tile--now {
    background: #fff;
  }
  /* ~~~ label styles ~~~ */
  .react-calendar__month-view__weekdays {
    text-align: center;
  }
  /* ~~~ button styles ~~~ */
  button {
    margin: 3px;
    background-color: #fff;
    border: 0;
    border-radius: 3px;
    color: #000;
    padding: 5px 0;
    &:hover {
      background-color: lightblue;
    }
    &:active {
      background-color: #935C5F;
    }
  }
  /* ~~~ day grid styles ~~~ */
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%; 
    
    .react-calendar__tile {
      max-width: initial !important;
    }
    .react-calendar__tile--range {
      box-shadow: 0 0 6px 2px lightblue;
    }
  }
  /* ~~~ neighboring month & weekend styles ~~~ */
  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
    color: gray !mportant;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #000;
  }

  /* ~~~ other view styles ~~~ */
  .react-calendar__year-view__months, .react-calendar__decade-view__years, .react-calendar__century-view__decades {
    display: grid !important;
    grid-template-columns: 20% 20% 20% 20% 20%;
    &.react-calendar__year-view__months {
      grid-template-columns: 33.3% 33.3% 33.3%;
    }
    
    .react-calendar__tile {
      max-width: initial !important;
    }
  }
`;

// const datesToAddClassTo = [tomorrow, in3Days, in5Days];

const CalendarContainer = () => {
  const [date, setDate] = useState();
  const [calendarContext, setCalendarContext] = useContext(CalendarContext);
  const tileContent = ({ date, view }) => {
    // currently only showing for current day
    let color = "red";

    if (dayjs(date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY')) {
      if (calendarContext["CalendarProgressValue"] >= 75) {
        color = "green";
      } else if (calendarContext["CalendarProgressValue"] >= 50) {
        color = "yellow";
      }
      return (<CalendarCircularProgress date={dayjs(date).format('D')} progress={calendarContext["CalendarProgressValue"]} color={color} />);
    } else {
      return (<CalendarCircularProgress date={dayjs(date).format('D')} progress={0} />);
    }

  }
  const onChange = (event) => {
    let dayjsInstance = dayjs(event);
    let year = dayjsInstance.get("year");
    let month = dayjsInstance.get("month");
    let day = dayjsInstance.get("date");
    // let contentRequestDate = `${year}-${month}-${day}`;
    let contentRequestDate = dayjs(event).format("YYYY-MM-DD");

    setCalendarContext({ contentRequestDate: contentRequestDate });
  }
  return (
    <CalendarStyle style={{ margin: "0px 30px 0px 50px" }}>
      <Calendar
        minDetail="month"
        maxDetail="month"
        view="month"
        tileContent={tileContent}
        onChange={onChange}
      />
    </CalendarStyle>
  );
}

export default CalendarContainer;
