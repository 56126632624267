import React, { createContext, useState } from "react";

const SidebarContext = createContext();

export const SidebarProviderRoute = ({ children }) => {
  const [active, setActive] = useState("home");

  return (
    <SidebarContext.Provider value={{ active, setActive }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
