import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../UserContext";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import LoginPrompt from "../LoginPrompt";
import { useMediaQuery, createTheme } from '@mui/material';
import LeftPane from "../LeftPane/LeftPane";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import OnScreenMenu from '../OnScreenMenu';
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import { IoRefresh } from "react-icons/io5";
import axios from "axios";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { CiImageOn } from "react-icons/ci";
import { FaRegFilePdf } from "react-icons/fa";
import { FilePlus } from "@phosphor-icons/react";
import { GoLock } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { XCircle } from "@phosphor-icons/react";
import {CircleLoader} from "react-spinners"
import { isLoggedIn, mainsAnswerReview } from "../../utils";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import "./index.css";
import { Helmet } from "react-helmet";
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step5 from './step5';
import {ConsolePage} from '../GPTAIInterview/pages/ConsolePage';
import {DAFOverviewContext} from "../../DAFOverviewContext";


const AIMockInterviewContainer =()=>{
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const gaEventTracker = useAnalyticsEventTracker("ai-answer-review");
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    wrapperIsLoggedIn();
    gaEventTracker(`${loggedInUserDetails.name ? loggedInUserDetails.name : "logged-out user"}/dashboard`);
  }, []);

  
  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
    //   setOpenLoginPrompt(true);
    }
  }; 

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  // });

  return (
    <>
      {isMobile ? (
        <>
        <Helmet>
            <title>UPSC Mains Answer Evaluation in 24 Hours – AI & Expert Reviewed | Toppers Choice for UPPSC, BPSC, TSPSC, APPSC, OPSC, JPSC, KPSC</title>
            <meta
              name="description"
              content="Instant Mains Answer Evaluation for UPSC IAS Mains Exam - Providing AI-powered Answer Review for IAS aspirants. Get your UPSC IAS mains answers and full-length tests(FLTs) evaluated in just 24 hours with CollectorBabu's unique AI plus human review. Best Evaluation platform for State PCS exams - UPPSC, BPSC, APPSC, TSPSC, GPSC, HPSC, JPSC, KPSC, PPSC, MPPSC, RPSC, OPSC, CGPSC, JKPSC, APSC, TNPSC, MPSC, UKPSC, WBPSC. Faster than traditional 7-day reviews, our service combines the precision of AI with the insight of experts. Join Mains Mentorship Program with Toppers. GS | Ethics | Essay | Optional all subjects covered."
            />
            <meta
              name="keywords"
              content="UPSC IAS Mains Exam, Mains 2025, AI Evaluation, AI Answer Evaluation, Answer Review Portal, Topper's Answers, Daily Answer Writing Initiative, Daily UPSC Mains Answer Writing Practice, Answer Review, UPSC Answer Writing Review, UPSC Answer Writing Practice, IAS Topper's Answers, Model Answers, Optional Answer Writing Practice, AI Answer Review, IAS Aspirants, Answer Writing Practice, CollectorBabu, UPSC Preparation, AI for UPSC, Secure Answer Writing, TLP, Abhyaas, Convert IAS, ConvertIAS, RAS, KAS, OAS, Mains Test Series, MGP, Expert Feedback, GS Answers, FLT Evaluation, AIR 167 IAS, AIR 309 IAS, Topper Answers UPSC, Rishabh Dev, Sociology Answer Writing, Anthropology Answer Writing, PSIR Answer Writing, 24-hour Feedback, FLT Evaluation, Mains Mentorship Program"
            />
            <meta
              property="og:title"
              content="Best Mains Answer Evaluation Platform | Full Test Evaluation in 24 hours | CollectorBabu"
            />
            <meta
              property="og:url"
              content="https://collectorbabu.com/ai-answer-review"
            />
            <meta
              property="og:description"
              content="Why wait a week? Get your UPSC mains answers evaluated in 24 hours by both AI and interview-appeared human experts at CollectorBabu. Get an instant AI-driven review of your answers for UPSC IAS Mains Exam."
            />
            <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <LeftPane Component={AIMockInterview} />
        <OnScreenMenu/>
    <Footer/>
        </>
      ):(  
        <>
        <Helmet>
            <title>UPSC Mains Answer Evaluation in 24 Hours – AI & Expert Reviewed | Toppers Choice for UPPSC, BPSC, TSPSC, APPSC, OPSC, JPSC, KPSC</title>
            <meta
              name="description"
              content="Instant Mains Answer Evaluation for UPSC IAS Mains Exam - Providing AI-powered Answer Review for IAS aspirants. Get your UPSC IAS mains answers and full-length tests(FLTs) evaluated in just 24 hours with CollectorBabu's unique AI plus human review. Best Evaluation platform for State PCS exams - UPPSC, BPSC, APPSC, TSPSC, GPSC, HPSC, JPSC, KPSC, PPSC, MPPSC, RPSC, OPSC, CGPSC, JKPSC, APSC, TNPSC, MPSC, UKPSC, WBPSC. Faster than traditional 7-day reviews, our service combines the precision of AI with the insight of experts. Join Mains Mentorship Program with Toppers. GS | Ethics | Essay | Optional all subjects covered."
            />
            <meta
              name="keywords"
              content="UPSC IAS Mains Exam, Mains 2025, AI Evaluation, AI Answer Evaluation, Answer Review Portal, Topper's Answers, Daily Answer Writing Initiative, Daily UPSC Mains Answer Writing Practice, Answer Review, UPSC Answer Writing Review, UPSC Answer Writing Practice, IAS Topper's Answers, Model Answers, Optional Answer Writing Practice, AI Answer Review, IAS Aspirants, Answer Writing Practice, CollectorBabu, UPSC Preparation, AI for UPSC, Secure Answer Writing, TLP, Abhyaas, Convert IAS, ConvertIAS, RAS, KAS, OAS, Mains Test Series, MGP, Expert Feedback, GS Answers, FLT Evaluation, AIR 167 IAS, AIR 309 IAS, Topper Answers UPSC, Rishabh Dev, Sociology Answer Writing, Anthropology Answer Writing, PSIR Answer Writing, 24-hour Feedback, FLT Evaluation, Mains Mentorship Program"
            />
            <meta
              property="og:title"
              content="Best Mains Answer Evaluation Platform | Full Test Evaluation in 24 hours | CollectorBabu"
            />
            <meta
              property="og:url"
              content="https://collectorbabu.com/ai-answer-review"
            />
            <meta
              property="og:description"
              content="Why wait a week? Get your UPSC mains answers evaluated in 24 hours by both AI and interview-appeared human experts at CollectorBabu. Get an instant AI-driven review of your answers for UPSC IAS Mains Exam."
            />
            <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <Box
      sx={{
        display: 'flex',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}
    >

        <Navbar  />
        <Box
        sx={{
          height: '100vh',
          backgroundSize: 'cover',
        }}
      >
        <Sidebar />

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <div style={{ marginTop: '75px',marginLeft:"90px", width:"93vw" }}> 
            <AIMockInterview />
        <Footer />
          </div>
        </Box>
        <OnScreenMenu/>
        </Box>
        </Box>
        </>
      )}
    </>
  );
}

const AIMockInterview = () => {
  const {activeStep, setActiveStep} = useContext(DAFOverviewContext);
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  // console.log("loggedInUserDetails", loggedInUserDetails);
  const [images, setImages] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [timer, setTimer] = useState(59);
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const renderStepComponent = () => {
    switch (activeStep) {
      case 1:
        return <Step1 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 2:
        return <Step2 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 3:
        return <Step3 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 4:
        return <ConsolePage activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 5:
        return <Step5 activeStep={activeStep} setActiveStep={setActiveStep} />;  
        default:
        return <Step1 activeStep={activeStep} setActiveStep={setActiveStep} />;
    }
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleLoginPromptOpen = () => {
    setOpenLoginPrompt(true);
  };

  const handleLoginPromptClose = () => {
    setOpenLoginPrompt(false);
  };

  

  return (
    <>
      {renderStepComponent()}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <LoginPrompt
        openLoginPrompt={openLoginPrompt}
        handleLoginPromptClose={handleLoginPromptClose}
      />
    </>
  );
};

export default AIMockInterviewContainer;
