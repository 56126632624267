import BootstrapDialogTitle from "./Modals/BootstrapDialogTitle";
import BootstrapDialog from "./Modals/Dialog";
import { DialogActions, DialogContent, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginPrompt = ({ openLoginPrompt, handleLoginPromptClose }) => {
  let navigate = useNavigate();

  const handleLoginButtonPrompt = () => {
    navigate("/login", { state: { from: window.location.pathname } });
  };

  return (
    <BootstrapDialog onClose={handleLoginPromptClose} aria-labelledby="login prompt" open={openLoginPrompt}>
      <BootstrapDialogTitle id="login with your google account" onClose={handleLoginPromptClose}>
        Login for this feature
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>Login to get access to this feature and many more...</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleLoginButtonPrompt}>
          Login
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default LoginPrompt;
