import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";

const ConfirmationExit = ({ closeAllModals, handleCloseNestedPaymentModalOnly, paymentModalNestedOpen }) => {
  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 270 } }} maxWidth="xs" open={paymentModalNestedOpen} keepMounted>
      <h1 className="mx-auto text-black font-semibold">You're almost there!!</h1>
      <DialogTitle>Are you sure you want to close? This might affect your payment if it is already in progress.</DialogTitle>
      <DialogActions>
      <Button 
                            variant="contained" 
                            style={{ 
                              width: "100%",
                              marginTop: "20px",
                              backgroundColor: "white",
                              padding: "10px 16px",
                              borderRadius: "8px",
                              border: "none",
                              fontFamily: "Manrope",
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "1",
                              textTransform: "none"  // This removes Bootstrap's default text transformation
                            }}
                            onClick={closeAllModals}
                          >
                            <span style={{ color: "#23b279" }}>
                              {/* Proceed to Pay ₹{(payData.amount / 100).toFixed(0)} */}
                              Yes
                            </span>
                          </Button>
                          <Button 
                            variant="contained" 
                            style={{ 
                              width: "100%",
                              marginTop: "20px",
                              backgroundColor: "#23b279",
                              padding: "10px 16px",
                              borderRadius: "8px",
                              border: "none",
                              fontFamily: "Manrope",
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "1",
                              textTransform: "none"  // This removes Bootstrap's default text transformation
                            }}
                            onClick={handleCloseNestedPaymentModalOnly}
                          >
                            <span style={{ color: "white" }}>
                              {/* Proceed to Pay ₹{(payData.amount / 100).toFixed(0)} */}
                              No
                            </span>
                          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationExit;
