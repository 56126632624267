import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiMenuAlt2 } from "react-icons/hi";
import UserContext from "../../UserContext";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import FastForwardIcon from "@mui/icons-material/FastForward";
// import AccountTreeIcon from "@mui/icons-material/AccountTree";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import AssistantIcon from "@mui/icons-material/Assistant";
// import PersonIcon from "@mui/icons-material/Person";
import { useSidebar } from "../../NavContext";
import {
   styled, 
  //  useTheme 
  } from "@mui/material/styles";
import Button from "@mui/material/Button";
import premiunIcon from "./premiumIcon.png"

import "./index.css";
// import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const BuyButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#20B486",
  color: "white",
  borderRadius: "6px",
  padding: "4px ",
  minHeight: "30px",
  fontFamily: "Roboto, sans-serif",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: 2,
  border: "1px solid rgba(31, 91, 67, 0.1)",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(228, 255, 224, 0.9)",
  },
}));

function Header() {
  const { sidebarOpen, setSidebarOpen, toggleSidebar } = useSidebar();
  const [loggedInUserDetails] = useContext(UserContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleNavigation = (path) => {
    setDropdownOpen(false);
    navigate(path);
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!e.target.closest(".n-navbar-userProfile")) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);
    return () => document.removeEventListener("mousedown", closeDropdown);
  }, []);

  return (
    <header className="n-navbar-header">
      <div className="n-navbar-logoContainer">
        <div className="n-navbar-homeIconWrapper">
          <HiMenuAlt2 onClick={toggleSidebar} size={30} />
        </div>
        <Link to="/">
          <img
            src="/images/mainLogo.png"
            alt="collectorbabu-logo"
            className="collectorbabu-logo"
          />
        </Link>
      </div>
      <nav className="n-navbar-navigationContainer">
          <PlanBadge 
          loggedInUserDetails={loggedInUserDetails} 
          premiunIcon={premiunIcon} 
        />
        <button
          onClick={() => navigate("/dashboard")}
          className="n-navbar-dashboardButton"
        >
          Dashboard
        </button>
        {loggedInUserDetails.plan !== "zenith_combo" &&
          loggedInUserDetails.plan !== "zenith" && (
            <BuyButton onClick={() => navigate("/pricing")}>Buy</BuyButton>
          )}
        <div className="n-navbar-userProfile">
        {loggedInUserDetails.user_id ? (
          <div
            className="n-navbar-profileContainer">
            <div className="n-navbar-profileImageWrapper">
              <Link to="/profile">
                <img
                  className="profile-image dropbtn"
                  src={loggedInUserDetails.picture}
                  alt="profile"
                />
              </Link>
            </div>
          </div>
          
          ) : (
            <BuyButton onClick={() => navigate("/login")}>Login</BuyButton>
            )}
         
          {dropdownOpen ? (
            <RiArrowDropUpLine size={30} onClick={handleDropdownToggle} />
          ) : (
            <RiArrowDropDownLine size={30} onClick={handleDropdownToggle} />
          )}
          {dropdownOpen && (
            <div className="n-navbar-dropdownMenu">
              <Link style={{ textDecoration: "none", color: "black"}} to="/upsc-mains-2024-essay-gs-questions-model-answers">
                <WorkOutlineIcon /> Mains 2024
              </Link>
              <Link  style={{ textDecoration: "none", color: "black"}} to="/upsc-mains-2023-essay-gs-questions-model-answers">
                <WorkOutlineIcon /> Mains 2023
              </Link>
              <Link  style={{ textDecoration: "none", color: "black"}} to="/upsc-notes-zero">
                <BookmarkBorderOutlinedIcon /> Notes Zero
              </Link>
              <Link style={{ textDecoration: "none", color: "black"}} to="/pyq-fast-track">
                <FastForwardIcon /> PYQ Fast track
              </Link>
              {/* <Link to="/upsc-ias-mock-interview"><PersonIcon /> Mock Interview</Link> */}
              {/* <Link to="/pyqexplorer"><FastForwardIcon />PYQ Explorer</Link> */}
              <Link style={{ textDecoration: "none", color: "black"}} to="/news">
                <AssistantIcon /> The Desi Explainers
              </Link>
              {/* <Link style={{ textDecoration: "none", color: "black"}} to="/upsc-dashboard">
                <AccountTreeIcon /> UPSC Dashboard
              </Link> */}
              {/* <Link style={{ textDecoration: "none", color: "black"}} to="/savedforlater">
                <BookmarkBorderOutlinedIcon /> Saved For Later
              </Link> */}
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

const PlanBadge = React.memo(({ loggedInUserDetails }) => {
  const planNameMap = {
    trial: "Trial Plan",
    basic: "Basic",
    zenith: "Zenith", 
    zenith_combo: "Zenith Combo"
  };

  if (!loggedInUserDetails?.premium) return null;

  const planName = planNameMap[loggedInUserDetails.plan];
  
  return (
    <div className="flex justify-between items-center gap-2">
      <img 
        src={premiunIcon} 
        alt="Premium Plan Icon" 
        className="w-5 h-5" 
      />
      <div className="my-auto h-fit hidden lg:block">
        {planName && (
          <div className="flex w-fit gap-1">
            <p>{planName}</p>
            {loggedInUserDetails.Subs_year && (
              <p>'{loggedInUserDetails.Subs_year}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export default Header;
