import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import './index.css';

export default function Banner({ handleRemoveBanner }) {
  let navigate = useNavigate();

  const handlePromotionClick = (event) => {
    navigate("/pricing");
  };

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden px-6 py-2 sm:px-3 sm:before:flex-1 banner-container">
      <div className="gradient-left" aria-hidden="true"></div>
      <div className="gradient-right" aria-hidden="true"></div>
      <div className="flex items-center gap-x-4 gap-y-2">
        <p className="leading-6 text-gray-900 promotion-text">
          Use code <span className="font-semibold">"GETEDGEINMAINS"</span> to get Special discount 🚀🌕
        </p>
        <button
          onClick={handlePromotionClick}
          className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Buy now! <span aria-hidden="true">&rarr;</span>
        </button>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          onClick={handleRemoveBanner}
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
