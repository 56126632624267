import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { IoIosArrowForward, IoIosArrowBack,IoIosMenu,IoMdList,IoIosAdd,IoMdRemove,IoIosArrowRoundBack,IoIosArrowRoundForward } from "react-icons/io";
import axios from 'axios';
import { useParams } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PdfPageViewer = () => {

    const { mains_answer_sheet_hash, user_id, pdf_name } = useParams();
    const [pdfUrl, setPdfUrl] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(100);
    const [scale, setScale] = useState(1.2);
    const [renderedPageNumber, setRenderedPageNumber] = useState(null);
    const [renderedScale, setRenderedScale] = useState(null);
    const pageRefs = useRef([]);

    //     useEffect(() => {
    //   const options = {
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 0.5,
    //   };
    
    //   const observer = new IntersectionObserver((entries, observer) => {
    //     entries.forEach((entry) => {
    //       if (entry.isIntersecting) {
    //         const visiblePageNumber = pageRefs.current.indexOf(entry.target) + 1;
    //         setPageNumber(visiblePageNumber);
    //       }
    //     });
    //   }, options);
    
    //   pageRefs.current.forEach((pageRef) => {
    //     if (pageRef) {
    //       observer.observe(pageRef);
    //     }
    //   });
    
    //   return () => {
    //     pageRefs.current.forEach((pageRef) => {
    //       if (pageRef) {
    //         observer.unobserve(pageRef);
    //       }
    //     });
    //   };
    // }, [numPages]);

    // const handlePageChange = (page) => {
    //     setPageNumber(page);
    // };

    const isUserInteraction = useRef(false);

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
     
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isUserInteraction.current) {
            const visiblePageNumber = pageRefs.current.indexOf(entry.target) + 1;
            setPageNumber(visiblePageNumber);
          }
        });
      }, options);
    
      pageRefs.current.forEach((pageRef) => {
        if (pageRef) {
          observer.observe(pageRef);
        }
      });
    
      return () => {
        pageRefs.current.forEach((pageRef) => {
          if (pageRef) {
            observer.unobserve(pageRef);
          }
        });
      };
    }, [numPages]);
    
    // Handle user interaction
    const handlePageChange = (page) => {
      isUserInteraction.current = true;
      setPageNumber(page);
      if (pageRefs.current[page - 1]) {
        pageRefs.current[page - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
        isUserInteraction.current = false;
    }, 10);
    };

      useEffect(() => {
        if (pageRefs.current[pageNumber - 1]) {
          pageRefs.current[pageNumber - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, [pageNumber]);
  
      useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await axios.post(
                'https://collectorbabu.com/api/mains/mains_answer_sheet_download',
                { user_id: user_id, mains_answer_sheet_hash: mains_answer_sheet_hash }, 
              );
              const pdfLink = response.data.pdf_url;
              setPdfUrl(pdfLink);
            } catch (error) {
              console.error('Error fetching PDF:', error);
            }
          };
      
          fetchData();
        }, []);
    
      function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        pageRefs.current = Array(numPages).fill(null);
      }
    
      const changePage = (param) => {
        if (param === "prev" && pageNumber > 1) {
          setPageNumber((prev) => prev - 1);
        }
        if (param === "next" && pageNumber < numPages) {
          setPageNumber((prev) => prev + 1);
        }
      };
     
      const downloadPDF = async () => {
        try {
          const response = await axios.post(
            "https://collectorbabu.com/api/mains/mains_answer_sheet_download",
            { user_id: user_id, mains_answer_sheet_hash: mains_answer_sheet_hash },
          );
          
          const pdfLink = response.data.pdf_url;
          const pdfResponse = await axios.get(
            pdfLink, { responseType: "blob" }
          );
      
          const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(blob);
      
          // Create a link to download the PDF
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', pdf_name || 'collectorBabu.pdf'); 
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); 
      
          // Open the PDF in a new window
          const newWindow = window.open();
          newWindow.location.href = fileURL;
      
          // Clean up the object URL after a delay to allow the new window to load the PDF
          setTimeout(() => {
            URL.revokeObjectURL(fileURL);
          }, 100);
      
        } catch (error) {
          console.error("Error downloading PDF:", error);
        } finally {
          // setLoadingActions(prevLoadingActions => ({ ...prevLoadingActions, [id]: false }));
          // setOpen(true);
        }
      };
        function changeScale(offset) {
          setScale((prevScale) => prevScale + offset);
        }
  
        function decreaseScale() {
          setScale((prevZoom) => Math.max((prevZoom - .1).toFixed(1), .5))
        }
      
        function increaseScale() {
          setScale((prevZoom) => Math.min((prevZoom + .1).toFixed(1), 2))
        }
      
        const isLoading =
          renderedPageNumber !== pageNumber || renderedScale !== scale;
      
        const handleZoomIn = () => setZoomLevel((prevZoom) => Math.min(prevZoom + 25, 200));
        const handleZoomOut = () => setZoomLevel((prevZoom) => Math.max(prevZoom - 25, 25));
  
        const handleWheel = (event) => {
          if (event.ctrlKey) {
            event.preventDefault();
            // setZoomLevel((prevZoom) => event.deltaY < 0 ? Math.min(prevZoom + 10, 200) : Math.max(prevZoom - 10, 25));
            setScale((prevZoom) => event.deltaY < 0 ? Math.min(prevZoom + .1, 2) : Math.max(prevZoom - .1, .5));
          }
        };
  
        useEffect(() => {
          const pdfContainer = document.getElementById('pdf-container');
          pdfContainer.addEventListener('wheel', handleWheel);
  
          return () => {
            pdfContainer.removeEventListener('wheel', handleWheel);
          };
        }, []);
  return (
  <>
    <div className="w-full h-screen flex justify-start items-start overflow-hidden bg-white">
    {showPreview && 
      <div className="border-r-2 border-r-black w-60 h-full">
          <div 
          className=" h-16 py-2 flex justify-center items-center text-white border-b-2 shadow-md shadow-[rgb(22,26,41)] text-center font-semibold text-lg"
          style={{
            // background: 'linear-gradient(to bottom, rgb(22,26,41)20%, black 100%)',
            background: '#2a2929'
          }}
          >
          Documents
          </div>
          <div className="h-full bg-[#2a2929]">
          <Document
              className="flex flex-col justify-start items-center overflow-auto h-full"
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => console.error('Failed to load PDF:', error)}
          >
              {Array.from({ length: numPages }, (_, index) => (
              <div
                  key={index}
                  // onClick={() => {
                  // setPageNumber(index + 1);
                  // }}
                  className={` cursor-pointer flex flex-col rounded my-2 ${pageNumber === index + 1 ? "border-green-700" : ""}`}
              >
                  <Page
                  height={180}
                  pageNumber={index + 1}
                  />
                  <span className="font-bold text-white mx-auto">  {index + 1}</span>
              </div>
              ))}
          </Document>
          </div>
      </div>
    }
    <div className='flex w-full max-h-screen'>
      <div className="w-[100%] h-[100%] flex flex-col">
          <div className="w-full h-[10%] bg-white">
            <div 
              className=" h-16 py-2 px-4 flex justify-between items-center"
              style={{
                // background: 'linear-gradient(to bottom, rgb(22,26,41)20%, black 100%)',
                background: '#2a2929'
              }}
            >
              <div className='flex items-center gap-2'>
                <IoIosMenu className='cursor-pointer text-2xl text-white' onClick={()=>setShowPreview(!showPreview)}/> 
                <div className="font-semibold text-lg text-white">{pdf_name}</div>
              </div>
              <div className="flex justify-center items-center gap-2 ml-[-2]">
                <IoIosArrowBack
                    className="cursor-pointer text-xl text-white"
                    onClick={() => changePage("prev")}
                />
                <div className="px-3 rounded bg-white">{pageNumber} / {numPages}</div>
                <IoIosArrowForward
                    className="cursor-pointer text-xl text-white"
                    onClick={() => changePage("next")}
                />
              </div>
              <div className='flex gap-2'>
                 <IoMdRemove className='text-xl text-white'onClick={decreaseScale}/>
                 <div className='bg-white px-1 rounded'>{(scale*100).toFixed(0)}%</div>
                 <IoIosAdd className='text-xl text-white'onClick={increaseScale}/>
              </div>
              <div>
                <button className="bg-black text-white p-4 cursor-pointer py-1 rounded shadow-md shadow-gray-700"  onClick={downloadPDF}>
                    Download
                </button>
              </div>
          </div>          
          <div id='pdf-container' className=" w-full h-screen overflow-auto relative">
            <Document
              className="flex flex-col justify-start items-center mb-5"
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => console.error('Failed to load PDF:', error)}
            >
                  {Array.from(new Array(numPages), (el, index) => (
                    <div key={index + 1} ref={(el) => (pageRefs.current[index] = el)}  className="mb-5">
                      {isLoading && renderedPageNumber === index + 1 && renderedScale ? (
                        <Page
                          key={renderedPageNumber + "@" + renderedScale}
                          className="prevPage"
                          pageNumber={renderedPageNumber}
                          scale={renderedScale}
                          width={1000}
                        />
                      ) : (
                        <Page
                          key={index + 1 + "@" + scale}
                          pageNumber={index + 1}
                          onRenderSuccess={() => {
                            setRenderedPageNumber(index + 1);
                            setRenderedScale(scale);
                          }}
                          scale={scale}
                          width={1000}
                        />
                      )}
                    </div>
                  ))}
            </Document> 
            <div className='sticky bottom-[50%] z-20 flex justify-between items-center'>
                    <div className='  ml-16 h-16 w-16  rounded-full bg-black shadow-md shadow-gray-700'>
                    <IoIosArrowRoundBack
                            className=" cursor-pointer m-auto mt-14 text-6xl text-white"
                            onClick={() => changePage("prev")}
                            />
                    </div>
                    <div className=' mr-16 h-16 w-16 rounded-full bg-black shadow-md shadow-gray-700'>
                    <IoIosArrowRoundForward
                            className=" cursor-pointer m-auto mt-14 text-6xl text-white"
                            onClick={() => changePage("next")}
                            />
                    </div>
                    </div>
          </div>
        </div>      
      </div>              
    </div>
  </div>
</>
);
}

export default PdfPageViewer