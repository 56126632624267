import React, { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';
import { getDocument } from 'pdfjs-dist';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './index.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfOrganizer = () => {
  const [file, setFile] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pages, setPages] = useState([]);
  const [cropping, setCropping] = useState(false);
  const [cropBox, setCropBox] = useState(null);
  const [cropPageNumber, setCropPageNumber] = useState(null);
  const canvasRefs = useRef([]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPages(Array.from({ length: numPages }, (_, index) => index + 1));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    const arrayBuffer = await file.arrayBuffer();
    setPdfData(arrayBuffer);
  };

  const removePage = (pageNumber) => {
    setPages(pages.filter(page => page !== pageNumber));
  };

  const handleMouseDown = (pageNumber, e) => {
    const rect = e.target.getBoundingClientRect();
    setCropBox({ startX: e.clientX - rect.left, startY: e.clientY - rect.top });
    setCropping(true);
    setCropPageNumber(pageNumber);
  };

  const handleMouseMove = (e) => {
    if (!cropping || cropBox === null || cropPageNumber === null) return;
    const rect = e.target.getBoundingClientRect();
    setCropBox(prevCropBox => ({
      ...prevCropBox,
      endX: e.clientX - rect.left,
      endY: e.clientY - rect.top,
    }));
  };

  const handleMouseUp = async () => {
    if (!cropping || cropBox === null || cropPageNumber === null) return;
    setCropping(false);

    const { startX, startY, endX, endY } = cropBox;
    const width = Math.abs(endX - startX);
    const height = Math.abs(endY - startY);
    const top = Math.min(startY, endY);
    const left = Math.min(startX, endX);

    try {
      const pdfDoc = await PDFDocument.load(pdfData);
      const pages = pdfDoc.getPages();
      const cropPageIndex = cropPageNumber - 1;

      if (cropPageIndex < 0 || cropPageIndex >= pages.length) {
        throw new Error(`Page ${cropPageNumber} not found in PDF document.`);
      }

      const page = pages[cropPageIndex];
      const { width: pageWidth, height: pageHeight } = page.getSize();

      const scaleX = pageWidth / canvasRefs.current[cropPageIndex].width;
      const scaleY = pageHeight / canvasRefs.current[cropPageIndex].height;
      const cropLeft = left * scaleX;
      const cropBottom = pageHeight - top * scaleY - height * scaleY;
      const cropWidth = width * scaleX;
      const cropHeight = height * scaleY;

      const pdfjsDoc = await getDocument({ data: pdfData }).promise;
      const pdfjsPage = await pdfjsDoc.getPage(cropPageNumber);
      const viewport = pdfjsPage.getViewport({ scale: 1 });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await pdfjsPage.render({ canvasContext: context, viewport }).promise;

      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;
      const croppedContext = croppedCanvas.getContext('2d');

      croppedContext.drawImage(
        canvas,
        cropLeft, cropBottom, cropWidth, cropHeight, 
        0, 0, cropWidth, cropHeight
      );

      const croppedImageBytes = await croppedCanvas.toDataURL('image/png');
      const croppedPdfDoc = await PDFDocument.create();
      const croppedImage = await croppedPdfDoc.embedPng(croppedImageBytes);
      const croppedPage = croppedPdfDoc.addPage([cropWidth, cropHeight]);
      croppedPage.drawImage(croppedImage, {
        x: 0,
        y: 0,
        width: cropWidth,
        height: cropHeight,
      });

      pdfDoc.removePage(cropPageIndex);
      const [croppedPageRef] = await pdfDoc.copyPages(croppedPdfDoc, [0]);
      pdfDoc.insertPage(cropPageIndex, croppedPageRef);

      const modifiedPdfBytes = await pdfDoc.save();
      setPdfData(modifiedPdfBytes);
      setNumPages(pdfDoc.getPageCount());
      setPages(Array.from({ length: pdfDoc.getPageCount() }, (_, index) => index + 1));
    } catch (error) {
      console.error('Error handling mouse up:', error);
    } finally {
      setCropBox(null);
      setCropPageNumber(null);
    }
  };

  const downloadPdf = () => {
    if (!pdfData) return;
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'modified.pdf';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      {file && (
        <div>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <div className="pages-container">
              {pages.map((pageNumber, index) => (
                <div
                  key={pageNumber}
                  className="page-item"
                  onMouseDown={(e) => handleMouseDown(pageNumber, e)}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  <canvas
                    ref={(el) => canvasRefs.current[index] = el}
                    width={600}
                    height={800}
                    style={{ display: 'none' }}
                  ></canvas>
                  <Page
                    pageNumber={pageNumber}
                    width={600}
                  />
                  <button className="pdf-crop-delete" onClick={() => removePage(pageNumber)}>Delete</button>
                  <div className="page-number">Page {pageNumber}</div>
                  {cropping && cropPageNumber === pageNumber && cropBox && (
                    <div
                      className="crop-overlay"
                      style={{
                        position: 'absolute',
                        top: cropBox.startY,
                        left: cropBox.startX,
                        width: cropBox.endX - cropBox.startX,
                        height: cropBox.endY - cropBox.startY,
                        border: '2px dashed rgba(0, 0, 0, 0.5)',
                        pointerEvents: 'none',
                        boxSizing: 'border-box',
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </Document>
          <button onClick={downloadPdf}>Download PDF</button>
        </div>
      )}
    </div>
  );
};

export default PdfOrganizer;
