import * as React from "react";
import { isIOS } from "react-device-detect";
import UPISelectScreen from "./UPISelectScreen";
import PaymentScreen from "./PaymentScreen";

const PaymentDialogParent = ({
  paymentModalOpen,
  handleCloseNestedPaymentModal,
  handleOpenNestedPaymentModal,
  payData,
  loaderPayModal,
  handleCloseNestedPaymentModalOnly,
  paymentModalNestedOpen,
  showUPISelectScreen,
  handleOpenUPISelectScreen,
  handleCloseUPISelectScreen,
  handlePaymentModalOpen,
  setSelectedUPIApp,
  clearPayIntervals,
  selectedUPIApp,
  selectedPlan,
  amount,
  year,
  PAYMENT_PENDING
}) => {
  
  return (
    <>
      {/* {isIOS && showUPISelectScreen ? (
        <UPISelectScreen
          showUPISelectScreen={showUPISelectScreen}
          handleOpenNestedPaymentModal={handleOpenNestedPaymentModal}
          handleCloseNestedPaymentModal={handleCloseNestedPaymentModal}
          handleCloseNestedPaymentModalOnly={handleCloseNestedPaymentModalOnly}
          paymentModalNestedOpen={paymentModalNestedOpen}
          handleCloseUPISelectScreen={handleCloseUPISelectScreen}
          handlePaymentModalOpen={handlePaymentModalOpen}
          setSelectedUPIApp={setSelectedUPIApp}
          loaderPayModal={loaderPayModal}
          payData={payData}
          clearPayIntervals={clearPayIntervals}
          selectedUPIApp={selectedUPIApp}
        />
      ) : ( */}
        <PaymentScreen
          paymentModalOpen={paymentModalOpen}
          handleCloseNestedPaymentModal={handleCloseNestedPaymentModal}
          handleOpenNestedPaymentModal={handleOpenNestedPaymentModal}
          payData={payData}
          loaderPayModal={loaderPayModal}
          handleCloseNestedPaymentModalOnly={handleCloseNestedPaymentModalOnly}
          paymentModalNestedOpen={paymentModalNestedOpen}
          selectedPlan={selectedPlan}
          amount={amount}
          year={year}
          PAYMENT_PENDING={PAYMENT_PENDING}
        />
      {/* )} */}
    </>
  );
};

export default PaymentDialogParent;
