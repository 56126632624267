import React from "react";

const getInitials = (name) => {
  const names = name.split(" ");
  return names
    .map((name) => name[0])
    .join("")
    .toUpperCase();
};

const InitialsImage = ({ name }) => {
  const initials = getInitials(name);
  const backgroundColors = [
    "#FFC107", 
    "#03A9F4", 
    "#4CAF50", 
    "#FF5722", 
    "#9C27B0", 
    "#E91E63", 
  ];
  const randomColor =
    backgroundColors[Math.floor(Math.random() * backgroundColors.length)];

  return (
    <div
      className="rounded-full overflow-hidden flex justify-center items-center"
      style={{
        width: "46px",
        height: "46px",
        backgroundColor: randomColor,
      }}
    >
      <span className="text-white text-lg font-bold">{initials}</span>
    </div>
  );
};

export default InitialsImage;
