import React from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { useContext, useEffect } from "react";
import { useMediaQuery, createTheme } from '@mui/material';
import LeftPane from "../LeftPane/LeftPane";
import OnScreenMenu from '../OnScreenMenu';
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import UserContext from "../../UserContext";
import { isLoggedIn } from "../../utils";
import { IoIosAddCircleOutline } from "react-icons/io";
import {useNavigate} from "react-router-dom";
import BottomNavbar from '../BottomNavbar';
import "./Home.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

const features = [
  {
    iconSrc:
      "/icons/answer-evaluation.gif",
      path:"/",
    title: "Mains Answer Evaluation",
},
  {
    iconSrc:
      "/icons/flash-card.gif",
    path:"/flash-card",  
    title: "AI-generated Flashcards",
 },
  {
    iconSrc:
      "/icons/360-reader.gif",
    path:"/360-reader",  
    title: "360 Reader Matching PYQs",
 },
  {
    iconSrc:
      "/icons/mock-interview.gif",
    path:"/upsc-ias-mock-interview",  
    title: "Mock Interview Preparation",
   },
];

const HomeContainer =()=>{
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const gaEventTracker = useAnalyticsEventTracker("ai-answer-review");
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    wrapperIsLoggedIn();
    gaEventTracker(`${loggedInUserDetails.name ? loggedInUserDetails.name : "logged-out user"}/dashboard`);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  
  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
    //   setOpenLoginPrompt(true);
    }
  }; 

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  // });

  return (
    <>
      {isMobile ? (
        <>
        <Helmet>
          <title>Home - Collector Babu</title>
          <meta
            name="keywords"
            content="Daily quiz, daily current affairs - from Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS and more"
          />
          <meta
            name="description"
            content="Personalised Daily dashboard for UPSC IAS. AI-based learning. Online learning. Track progress in exam preparation. UPSC study materials. | Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS | The Hindu | The Indian Express | SansadTV"
          />

          <meta property="og:title" content="Daily Quiz, Daily Current Affairs" />
          <meta property="og:url" content="https://collectorbabu.com/" />
          <meta
            property="og:description"
            content="Practice and Track Daily Current Affairs, Previous Year Questions for UPSC IAS - Pre and Mains, State PCS and many other government exams"
          />
          <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <LeftPane Component={Home} />
        <OnScreenMenu/>
    <Footer/>
        </>
      ):(  
        <>
        <Helmet>
          <title>Home - Collector Babu</title>
          <meta
            name="keywords"
            content="Daily quiz, daily current affairs - from Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS and more"
          />
          <meta
            name="description"
            content="Personalised Daily dashboard for UPSC IAS. AI-based learning. Online learning. Track progress in exam preparation. UPSC study materials. | Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS | The Hindu | The Indian Express | SansadTV"
          />

          <meta property="og:title" content="Daily Quiz, Daily Current Affairs" />
          <meta property="og:url" content="https://collectorbabu.com/" />
          <meta
            property="og:description"
            content="Practice and Track Daily Current Affairs, Previous Year Questions for UPSC IAS - Pre and Mains, State PCS and many other government exams"
          />
          <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <Box
      sx={{
        display: 'flex',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}
    >

        <Navbar  />
        <Box
        sx={{
          height: '100vh',
          backgroundSize: 'cover',
        }}
      >
        <Sidebar />

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <div style={{ marginTop: '75px',marginLeft:"100px", width:"93.5vw"}}> 
            <Home />
        <Footer />
          </div>
        </Box>
        <OnScreenMenu/>
        </Box>
        </Box>
        </>
      )}
    </>
  );
}

const Home = () => {

  const navigate = useNavigate();

  const handleMoreClick = (title) => {
    console.log(`Learn more about ${title}`);
  };

  return (
    <>
    {/* <div className="answer-evaluation-container"> */}

      {/* <Navbar /> */}
      {/* <div className="container-side-and-center"> */}
        {/* <div className="answer-evaluation-content"> */}        
        <div style={{height:"100vh"}} className="dashboard-container-sub">
          {/* <section className="container-dashboard-new"> */}
          <div>
            <h2 className="title">
              <span className="titleText">
              Learn, Evaluate, Succeed – <span className="titleHighlight">Go Beyond</span> the Ordinary Prep{" "}
              </span>
              <span className="titleHighlight"></span>
            </h2>
            </div>
            <div className="cardGrid">
              {features.map((feature, index) => (
                <div >
                <article key={index} onClick={() => navigate(feature.path)} className="featureCard">
                  <img
                    loading="lazy"
                    src={feature.iconSrc}
                    className="featureIcon"
                    alt={`${feature.title} feature icon`}
                  />
                  <div className="featureInfo">
                    <h3 className="featureText">{feature.title}</h3>
                    <button
                      className="moreButton"
                      aria-label={`Learn more about ${feature.path}`}
                    >
                      <IoIosAddCircleOutline size={30} />
                    </button>
                  </div>
                </article>
                </div>
              ))}
            </div>
          {/* </section> */}
        </div>
      {/* </div> */}
      {/* </div> */}
      <BottomNavbar/>
    </>
  );
};

export default HomeContainer;
