import * as React from "react";
import "./index.css";
import {DAFOverviewContext} from "../../DAFOverviewContext";
import { useState, useContext } from "react";

export default function MockInterview() {
  
  const { setActiveStep } = useContext(DAFOverviewContext);

  const handleNext = () => {
    setActiveStep(2); 
  };

  return (
    <div className="interview-container">
      <div className="content-wrapper">
        <div className="header-section">
          <div className="title-wrapper">
            <h1 className="main-title">Mock Interview</h1>
            <p className="subtitle">
              Welcome to our AI-Enabled audio mock interview!
            </p>
          </div>
          <div className="category-badge">UPSC MAINS</div>
        </div>
        <div className="description-section">
          <p className="primary-text">
            Practice and improve your interview skills with our user-friendly
            tool that simulates a real interview experience. Please note that
            this is not a replacement for a real interview. Like other mock
            tests, it will only help you practice. Unlimited times.
          </p>
          <p className="secondary-text">
            Stay tuned for upcoming features like video interviews,
            recordings, and personalized feedback. But to move forward, we
            will need some ❤️ from your side!
          </p>
        </div>
        <button 
          className="start-button"
          onClick={handleNext}
          tabIndex={0}
          aria-label="Start practicing mock interview"
        >
          Start practicing
        </button>
      </div>
    </div>
  );
}