import React, { useState } from 'react';
import {X} from "@phosphor-icons/react"
import './index.css';

const OnScreenMenu = () => {
  const [menuVisible, setMenuVisible] = useState(true);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      {menuVisible && (
        <div className="toggle-on-screen-menu">
          <button onClick={toggleMenu} className="close-button">
            <X size={20}/>
          </button>
          <a href="https://t.me/collector_babu_original" target="_blank" rel="noopener noreferrer">
            <img className="telegram-icon-gif" src="icons/telegram-ani.gif" alt="telegram-icon"/>
          </a>
        </div>
      )}
    </>
  );
};

export default OnScreenMenu;
