import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import UserContext from "../../UserContext";
import OnScreenMenu from '../OnScreenMenu';
import { useMediaQuery, createTheme } from '@mui/material';
import LeftPane from "../LeftPane/LeftPane";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import DOMPurify from 'dompurify';
import { useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import {isLoggedIn} from "../../utils";
import ReactGA from "react-ga4";
import Footer from "../Footer/Footer";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";


const MainsAnswerDashboard =()=>{
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const gaEventTracker = useAnalyticsEventTracker("ai-answer-review-answer");
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    wrapperIsLoggedIn();
    gaEventTracker(`${loggedInUserDetails.name ? loggedInUserDetails.name : "logged-out user"}/dashboard`);
  }, []);

  
  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    } else {
    //   setOpenLoginPrompt(true);
    }
  }; 

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  return (
    <>
      {isMobile ? (
        <>
        <LeftPane Component={MainsAnswer} />
        <OnScreenMenu/>
    <Footer/>
        </>
      ):(  
        <>
        <Box
      sx={{
        display: 'flex',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100%',
      }}
    >

        <Navbar  />
        <Box
        sx={{
          height: '100vh',
          backgroundSize: 'cover',
        }}
      >
        <Sidebar />

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <div style={{ marginTop: '75px',marginLeft:"90px", width:"101%"}}> 
            <MainsAnswer />
        <Footer />
          </div>
        </Box>
        <OnScreenMenu/>
        </Box>
        </Box>
        </>
      )}
    </>
  );
}


const MainsAnswer = ({ activeQuestionIndex, mainsQuestion, showManualQuestionUpload, modelAnswer }) => {
  const location = useLocation();
  const { mainsAnswer } = location.state || {};

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const answerSections = Array.isArray(mainsAnswer) ? mainsAnswer : [];

  return (
<>
    <Card sx={{ minWidth: 275, maxWidth: 750, height: 'auto', margin: '10px auto' }}>
      <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src="/images/CBLogoAnswerE.png"
          alt="AI Review Logo"
          style={{ width: 70, aspectRatio: '1.01', objectFit: 'contain', marginBottom: '20px' }}
        />
        </Box>
        <Box
        component="header"
        sx={{
          borderRadius: "8px 8px 0 0",
          backgroundColor: "rgba(228, 255, 224, 1)",
          // border: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottomWidth: 1,
          maxWidth: 750,
          overflow: "hidden",
          padding: "6px 16px",
          "@media (max-width: 991px)": {
            paddingRight: "20px",
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 300,
            lineHeight: 1,
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          CollectorBabu AI Answer Review
        </Typography>
      </Box>
        <div style={{ padding: '20px 30px' }}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{  p: 2, whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(mainsAnswer) }}
          />
          {mainsAnswer && (
            <>
             
            </>
          )}
          {answerSections.map((section, index) => (
            <section key={index} style={{ padding: '10px 0' }}>
              <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
                {section.title}
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {section.content}
              </Typography>
            </section>
          ))}
        </div>
      </CardContent>
    </Card>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <button style={{ marginTop: '20px', marginBottom: '20px', padding: '10px 20px', backgroundColor: '#23B279', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', fontSize: '16px', fontWeight: 'bold' }} onClick={() => window.history.back()}>Back</button>
    </Box>
    </>
  );
};

export default MainsAnswerDashboard;

