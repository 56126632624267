import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import AssistantIcon from '@mui/icons-material/Assistant';
import { BiSolidHomeSmile } from "react-icons/bi";
import { PiNotepadLight } from "react-icons/pi";
import FastForwardIcon from '@mui/icons-material/FastForward';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { MdOutlineMenuBook } from "react-icons/md";
import { FaFileAlt, FaComments, FaBars } from 'react-icons/fa';

const BottomNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => setMenuVisible(!menuVisible);

  const menuItems = [
    { icon: <WorkOutlineIcon size={20} />, label: 'Mains 2024', route: '/upsc-mains-2024-essay-gs-questions-model-answers' },
    { icon: <WorkOutlineIcon size={20} />, label: 'Mains 2023', route: '/upsc-mains-2023-essay-gs-questions-model-answers' },
    { icon: <BookmarkBorderOutlinedIcon size={20} />, label: 'Notes Zero', route: '/upsc-notes-zero' },
    { icon: <FastForwardIcon size={20} />, label: 'PYQ Explorer', route: '/pyqexplorer' },
    { icon: <FastForwardIcon size={20} />, label: 'PYQ Fasttrack', route: '/pyq-fast-track' },
    { icon: <AssistantIcon size={20} />, label: 'Desi Explainers', route: '/news' },
    // { icon: <AccountTreeIcon size={20} />, label: 'UPSC Dashboard', route: '/pyqexplorer' },
    // { icon: <BookmarkBorderOutlinedIcon size={20} />, label: 'Saved For Later', route: '/savedforlater' },
  ];

  const isActive = (route) => location.pathname === route;

  return (
    <div className="bottom-navbar">
      <div
        className={`nav-item ${isActive('/home') ? 'active' : ''}`}
        onClick={() => navigate('/home')}
      >
        <BiSolidHomeSmile size={24} />
        <span>Home</span>
      </div>
      <div
        className={`nav-item ${isActive('/ai-answer-review') ? 'active' : ''}`}
        onClick={() => navigate('/ai-answer-review')}
      >
        <PiNotepadLight size={24} />
        <span>Evaluation</span>
      </div>
      <div
        className={`nav-item ${isActive('/360-reader') ? 'active' : ''}`}
        onClick={() => navigate('/360-reader')}
      >
        <MdOutlineMenuBook size={24} />
        <span>360 Reader</span>
      </div>
      <div
        className={`nav-item ${isActive('/flash-card') ? 'active' : ''}`}
        onClick={() => navigate('/flash-card')}
      >
        <FaFileAlt size={24} />
        <span>Flashcards</span>
      </div>
      <div
        className={`nav-item ${isActive('/upsc-ias-mock-interview') ? 'active' : ''}`}
        onClick={() => navigate('/upsc-ias-mock-interview')}
      >
        <FaComments size={24} />
        <span>Interview</span>
      </div>
      <div className="nav-item" onClick={toggleMenu}>
        <FaBars size={24} />
        <span>Menu</span>
      </div>

      {menuVisible && (
        <div className="menu-popup-bottom-nav">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={`menu-item-bottom-nav ${isActive(item.route) ? 'active' : ''}`}
              onClick={() => {
                setMenuVisible(false);
                navigate(item.route);
              }}
            >
              <div>{item.icon}</div>
              <div>
                <span className="menu-item-label-bottom-nav">{item.label}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BottomNavbar;
