import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from "react-router-dom";
import './index.css';

const AIFooter = () => {
  return (
    <footer>
      <div className='footer-container'>
        <div className="footer-row">
          <div className="footer-col">
            <h3 className="footer-heading">Collector Babu <div className="under-line"><span></span></div></h3>
            <p className= "ftp">Instant Answer Review for UPSC IAS Mains Exam - Providing instant AI-driven review for IAS | PCS aspirants undertaking answer writing practice.</p>  
          </div>
          {/* <div className="footer-col">
            <h3 className="footer-heading">Office <div className="under-line"><span></span></div></h3>
            <p className= "ftp">New Delhi</p>
            <p className= "ftp">Delhi</p>
            <p className= "ftp">110001</p>
            <p className="footer-email ftp">support@collectorbabu.com</p>
          </div> */}
          <div className="footer-col">
            {/* <h3 className="footer-heading">Reviews<div className="under-line"><span></span></div></h3> */}
            <h3 className="footer-heading"> </h3>
            <ul className='footer-list-items'>
              <li className= "ftp"><Link to="/ai-answer-review">AI Answer Review</Link></li>
              <li className= "ftp"><Link to="/upsc-mains-2024-essay-gs-questions-model-answers">Mains 2024</Link></li>
              <li className= "ftp"><Link to="/pyqexplorer">PYQ Explorer</Link></li>
              <li className= "ftp"><Link to="/360-reader">360 Reader</Link></li>
            </ul>
          </div>
          <div className="footer-col">
          {/* <h3 className="footer-heading">Exam Links<div className="under-line"><span></span></div></h3> */}
          <h3 className="footer-heading"> </h3>
          <ul className='footer-list-items'>
              <li className= "ftp"><Link to="/upsc-ias-mock-interview">Mock Interview</Link></li>
              <li className= "ftp"><Link to="/pyq-fast-track">PYQ Fast Track</Link></li>
              <li className= "ftp"><Link to="/upsc-notes-zero">Notes Zero</Link></li>
              <li className= "ftp"><Link to="/upsc-dashboard">UPSC Dashboard</Link></li>
            </ul>
            
          </div>
          <div className="footer-col">
          <h3 className="footer-heading">Contact Us<div className="under-line"><span></span></div></h3>
          <ul className='footer-list-items'>
              <li className= "ftp"><a href="https://t.me/collector_babu_original" target="_blank" rel="noopener noreferrer"><TelegramIcon className="social-icons"/>Telegram</a></li>
              <li className= "ftp"><a href="mailto:support@collectorbabu.com"><EmailIcon className="social-icons"/>Email</a></li>
              <li className= "ftp"><a href="https://www.youtube.com/@collectorbabu_original" target="_blank" rel="noopener noreferrer"><YouTubeIcon className="social-icons"/>YouTube</a></li>
            </ul>
            
        </div>
        </div>
    {/* <div className="social">
          <div className='social-icons-container'>
      <a href="https://t.me/collector_babu_original" target="_blank" rel="noopener noreferrer"><TelegramIcon className="social-icons"/></a>
      <a href="mailto:support@collectorbabu.com"><EmailIcon className="social-icons"/></a>
      <a href="https://www.youtube.com/@collectorbabu_original" target="_blank" rel="noopener noreferrer"><YouTubeIcon className="social-icons"/></a>
    </div>
          </div>  */}
        <hr/>
        <p className='copy-right'>COPYRIGHT © COLLECTOR BABU 2024</p>
      </div>
    </footer>
  );
}

export default AIFooter;
