import React, {useState, useEffect} from 'react'
import './Loaderb2c.css'

const Loaderb2c = () => {
    const [quote, setQuote] = useState("Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill");

    const quotes = [
        "Thinking is like a game; it does not begin unless there is an opposite team.",
        "Visionary decision-making happens at the intersection of intuition and logic.",
        "Not all who wander are lost.",
        "Inspiration for creativity springs from the effort to look for the magical in the mundane.",
        "Girls are weighed down by restrictions, boys with demands — two equally harmful disciplines.",
        "Mathematics is the Music of Reason.",
        "A society that has more justice is a society that needs less charity.",
        "Education is what remains after one has forgotten what one has learned in school.",
        "A smile is the chosen vehicle for all ambiguities.",
        "Just because you have a choice, it does not mean that any of them has to be right.",
        "The time to repair the roof is when the sun is shining.",
        "You can not step twice in the same river.",
        "A ship in harbour is safe, but that is not what ship is for.",
        "Poets are the unacknowledged legislators of the world",
        "History is a series of victories won by the scientific man over the romantic man",
        "Your perception of me is a reflection of you; my reaction to you is an awareness of me.",
        "Philosophy of wantlessness is Utopian, while materialism is a chimera.",
        "Hand that rocks the cradle rules the world.",
        "The real is rational and the rational is real.",
        "History repeats itself, first as a tragedy, second as a farce.",
        "What is research, but a blind date with knowledge!",
        "There are better practices to “best practices”.",
        "The process of self-discovery has now been technologically outsourced.",
        "Life is long journey between human being and being humane.",
        "Mindful manifesto is the catalyst to a tranquil self.",
        "Ships do not sink because of water around them; ships sink because of water that gets into them.",
        "Simplicity is the ultimate sophistication.",
        "Culture is what we are, civilization is what we have.",
        "There can be no social justice without economic prosperity but economic prosperity without social justice is meaningless.",
        "Patriarchy is the least noticed yet the most significant structure of social inequality.",
        "Values are not what humanity is, but what humanity ought to be.",
        "Courage to accept and dedication to improve are two keys to success.",
        "Wisdom finds truth.",
        "South Asian societies are woven not around the state, but around their plural cultures and plural identities.",
        "Best for an individual is not necessarily best for the society.",
        "Neglect of primary health care and education in India are reasons for its backwardness.",
        "A good life is one inspired by love and guided by knowledge.",
        "A people that value its privileges above its principles lose both.",
        "“The past’ is a permanent dimension of human consciousness and values.",
        "Customary morality cannot be a guide to modern life."
      ];


      useEffect(() => {
        const getRandomQuote = () => {
          const randomIndex = Math.floor(Math.random() * quotes.length);
          setQuote(quotes[randomIndex]);
        };
      
        const interval = setInterval(() => {
          getRandomQuote();
        }, 5000); 
      
        return () => clearInterval(interval);
      }, []);
      
  return (
    
    <div className="loader-overlay-b2c">
     <p className='quote-loader-b2c'>{quote}</p>
    <div className="wrapper">
    <div className="box-wrap">
        <div className="box-b2c one"></div>
        <div className="box-b2c two"></div>
        <div className="box-b2c three"></div>
        <div className="box-b2c four"></div>
        <div className="box-b2c five"></div>
        <div className="box-b2c six"></div>
    </div>
</div>
     <p className='quote-loader-b2c-f'>Please wait while your answer is getting evaluated</p>
</div>
  )
}

export default Loaderb2c
