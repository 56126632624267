import React, { useEffect } from "react";
import ViewSDKClient from "../../ViewSDKClient";

const RenderMenu = ({ url , pdfName}) => {

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const viewSDKClient = new ViewSDKClient();
        await viewSDKClient.ready();
        viewSDKClient.previewFile("pdf-div", {
          defaultViewMode: "FULL_WIDTH",
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: false,
          showDownloadPDF: false,
          showPrintPDF: false,
          enableAnnotationAPIs: false,
          includePDFAnnotations: false,
          enableAnnotationEvents: false,
          downloadWithAnnotations: false,
          printWithAnnotations: false,
        }, url, pdfName);
      } catch (error) {
        console.error(error);
      }
    };

    loadPDF();
  }, [url, pdfName]);

  return (
    <div
      style={{ height: "100vh" }}
      id="pdf-div"
      className="full-window-div border border-gray-100 h-screen"
    ></div>
  );
};

export default RenderMenu;