import React from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../UserContext";

function ProtectedRoutes(props) {
  const { Component, path } = props;
  const [loggedInUserDetails, setLoggedInUserDetails] = React.useContext(UserContext);

  if (loggedInUserDetails.name) {
    return <Component {...props} />;
  } else {
    return <Navigate to="/login" state={{ from: path }} />;
  }
}

export default ProtectedRoutes;
