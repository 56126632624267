// import AIFooter from '../AIFooter';
import Navbar from '../Navbar';
import Footer from '../Footer/Footer';
// import './index.css'
import OnScreenMenu from '../OnScreenMenu';

import { useEffect,useState } from 'react';
export default function TermsOfService() {
    
  return (
    <>
    <Navbar/>
    <div className="terms-and-conditions mt-[78px]">
        <h1 className="cancellation-title">Return and Cancellation Policy</h1>
        <p className="cancellation-date">Last Updated October 2, 2024</p>

        <h2 className="cancellation-subtitle">Refunds</h2>
        <p className="cancellation-text">
          All sales are final and no refund will be issued. We provide a free trial, so please use that and decide to make the purchase based on that.
        </p>

        <h2 className="cancellation-subtitle">Cancellation</h2>
        <p className="cancellation-text">
          We do not provide cancellations to our service once availed.
        </p>

        <h2 className="cancellation-subtitle">Additional Information</h2>
        <p className="cancellation-text">
          For more details on the usage of our services, kindly refer to our Terms and Conditions. Your
          satisfaction is important to us, and we strive to deliver the best experience. However, due to 
          the nature of our services, all transactions are final.
        </p>
    </div>
  <OnScreenMenu/>
    <Footer/>
  </>
  );
}