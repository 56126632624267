import { useEffect, useRef, useCallback, useState, useContext } from "react";

import { RealtimeClient } from "@openai/realtime-api-beta";
import { ItemType } from "@openai/realtime-api-beta/dist/lib/client.js";
import { WavRecorder, WavStreamPlayer } from "../lib/wavtools/index.js";
import {DAFOverviewContext } from "../../../DAFOverviewContext";

import { X, Edit, Zap, ArrowUp, ArrowDown } from "react-feather";
import { Button } from "../components/button/Button";

import "./ConsolePage.scss";
import "./index.css";
import Navbar from "../../Navbar";

const LOCAL_RELAY_SERVER_URL: string = "wss://collectorbabu.com:8001";

  // console.log("REACT_APP_LOCAL_RELAY_SERVER_URL_FINAL", process.env.REACT_APP_LOCAL_RELAY_SERVER_URL);
  // console.log("REACT_APP_LOCAL_RELAY_SERVER_URL",LOCAL_RELAY_SERVER_URL)
interface RealtimeEvent {
  time: string;
  source: "client" | "server";
  count?: number;
  event: { [key: string]: any };
}

export function ConsolePage() {
  const { DafOverview, setDafOverview, setActiveStep, mockInterviewData, setMockInterviewData } = useContext(DAFOverviewContext);
  const apiKey = LOCAL_RELAY_SERVER_URL || "";
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : {
            apiKey: apiKey,
            dangerouslyAllowAPIKeyInBrowser: true,
          }
    )
  );

  const startTimeRef = useRef<string>(new Date().toISOString());

  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    changeTurnEndType();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `hello`,
        // text: `For testing purposes, I want you to list ten car brands. Number each item, e.g. "one (or whatever number you are one): the item name".`
      },
    ]);

    if (client.getTurnDetectionType() === "server_vad") {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  const formatToParagraph = () => {
    if (!DafOverview) return "";

    const { academicFields, personalFields } = DafOverview;

    const academicParagraph = `
      The candidate is from the state of ${academicFields.state}, district ${academicFields.district}. 
      They have pursued ${academicFields.stream} and their optional subject is ${academicFields.optionalSubjects}.
    `;
    
    const personalParagraph = `
      In terms of personal interests, the candidate enjoys ${personalFields.sports} and is involved in ${personalFields.extracurricularActivities}.
      They have held leadership positions: ${personalFields.leadershipPosition || "N/A"} and have received the following prizes: ${personalFields.prizes || "N/A"}.
    `;
    
    return `${academicParagraph} ${personalParagraph}`;
  };


  const Instructions = `Act as a strict, old-aged UPSC Personality Test interviewer who seems irritated after conducting day-long interviews and uses a Hinglish accent (though the language is strictly English-only). Your goal is to ask "Wow" type questions that are short, smart, and designed to prompt powerful responses, directly inspired by the candidate's Detailed Application Form (DAF). You should emulate the directness of an academic viva.

Your knowledge is deep in at least two different areas mentioned in the DAF, so feel free to make clever, surprising connections between topics. Use this to add an element of surprise to questions. Follow-up questions are encouraged and should be used unpredictably to keep the candidate focused and on their toes. Follow-ups should be very tough and in direct harsh tone. Aim to cover a wide breadth of topics.

Keep your tone conversational and natural, balancing formal and informal styles as with real-life interviews—make it sound spontaneous. Your task is to be friendly, yet strict and surprising. Remember to not appear polite, be strict.

# Instructions:
- Keep questions very short, just as they would be in a viva.
- Mix up the questions to cover multiple areas (breadth) while sometimes delving deeper (depth).
- Speak with a Hinglish tone(but english language): slightly nagging, yet casual and relatable.
- Never elaborate on or praise the candidate's answers; simply move on to the next question.
- Avoid passing comments on responses. Keep the student guessing whether you liked or disliked the response.

DAF :- ${formatToParagraph()}`;





  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
    setActiveStep(5)
  }, []);



  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;

    // Always use server_vad for automatic turn detection
    client.updateSession({ turn_detection: { type: "server_vad" } });

    // Start recording if connected
    if (client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  };

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll("[data-conversation-content]")
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  useEffect(() => {
    if (items.length > 0) {
      const conversationData = items.map((item) => ({
        role: item.role,
        content: item.formatted.transcript || item.formatted.text || "",
      }));
      setMockInterviewData(conversationData);
    }
  }, [items, setMockInterviewData]);



  useEffect(() => {
    const client = clientRef.current;

    const voices = [
      "alloy",
      "ash",
      "ballad",
      "coral",
      "echo",
      "sage",
      "shimmer",
      "verse",
    ];

    const updateVoice = () => {
      const randomVoice = voices[Math.floor(Math.random() * voices.length)] as
        | "alloy"
        | "ash"
        | "ballad"
        | "coral"
        | "echo"
        | "sage"
        | "shimmer"
        | "verse";
      client.updateSession({ voice: randomVoice });
    };

    const intervalId = setInterval(updateVoice, 3 * 60 * 1000);
    // const intervalId = setInterval(updateVoice, 10 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    //Set Voice
    // Array of voices
    const voices = [
      "alloy",
      "ash",
      "ballad",
      "coral",
      "echo",
      "sage",
      "shimmer",
      "verse",
    ];

    // Select a random voice
    const randomVoice = voices[Math.floor(Math.random() * voices.length)] as
      | "alloy"
      | "ash"
      | "ballad"
      | "coral"
      | "echo"
      | "sage"
      | "shimmer"
      | "verse";

    // Set Voice
    client.updateSession({ voice: randomVoice });

    // Set instructions
    client.updateSession({ instructions: Instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: "whisper-1" } });

    // handle realtime events from client + server for event logging
    client.on("realtime.event", (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on("error", (event: any) => console.log(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on("conversation.updated", async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  const [participants] = useState([
    {
      id: 1,
      name: "S. Sharma",
      imageUrl:"/images/AIInterview/interviewer1.jpg",
      isAudioActive: true,
    },
    {
      id: 2,
      name: "M. Sathya",
      imageUrl:"/images/AIInterview/interviewer4.jpg",
      isAudioActive: true,
    },
    {
      id: 3,
      name: "R. Shukla",
      imageUrl:"/images/AIInterview/interviewer2.jpg",      
      isAudioActive: true,
    },
    {
      id: 4,
      name: "D. Aggarwal",
      imageUrl:"/images/AIInterview/interviewer3.jpg",
      isAudioActive: true,
    },
  ]);

  const [controls] = useState([
    {
      id: 1,
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/e0b56e2a36bd9b982a71075e8ebd74f9ff94b09e8d3fb8630dd249ecc48ace57?placeholderIfAbsent=true&apiKey=ea79cd43c29c4ccba6c448c9e3098375",
      alt: "Microphone control",
      isActive: true,
    },
    {
      id: 2,
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/a14257a8f657417eaccbe451aa11a818bc133c70c064c40cf3e2e62c198e3a71?placeholderIfAbsent=true&apiKey=ea79cd43c29c4ccba6c448c9e3098375",
      alt: "Camera control",
      isActive: true,
    },
    {
      id: 3,
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/29e195731fff3633a5c7f25845896b02b5dfcd7f760df4724e622a82e0c6a874?placeholderIfAbsent=true&apiKey=ea79cd43c29c4ccba6c448c9e3098375",
      alt: "Screen share control",
      isActive: false,
    },
    {
      id: 4,
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/849d38591efe0d18c76f8ab6e76fd73290a767a38d9d153b9d144ebebf4277a3?placeholderIfAbsent=true&apiKey=ea79cd43c29c4ccba6c448c9e3098375",
      alt: "Chat control",
      isActive: false,
    },
    {
      id: 5,
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/132d9028aea9ed2073c93d47e3fe68b34c93883a2b97fad60c5b09566b572990?placeholderIfAbsent=true&apiKey=ea79cd43c29c4ccba6c448c9e3098375",
      alt: "End call control",
      isActive: false,
    },
  ]);

  const [recordingTime, setRecordingTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isVideoActive, setIsVideoActive] = useState(false);

  const startCamera = async () => {
    if (!isRecording) {
      setIsRecording(true);
      const startTime = Date.now();
      const id = setInterval(() => {
        setTimeElapsed(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
      setIntervalId(id);
    }
    try {
      
      const videoElement = document.getElementById(
        "live-video"
      ) as HTMLVideoElement | null;
      if (!videoElement) {
        console.error("Video element not found");
        return;
      }

      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false, // Set to true if you also need audio
      });
      videoElement.srcObject = mediaStream;
      setStream(mediaStream);
      setIsVideoActive(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    setIsRecording(false);
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      setStream(null);
      setIsVideoActive(false);
    }
  };
  
  const [recording, setRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);


  const toggleCamera = () => {
    if (isVideoActive) {
      stopCamera();
    } else {
      startCamera();
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const formatTime = (seconds: number) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hours}:${minutes}:${secs}`;
  };

  return (
    <>
      {/* <Navbar/> */}
      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: "#262626",
          padding: "30px",
        }}
      >
        <div className="video-call">
          <div className="participants-grid">
            {participants.map((participant) => (
              <div
                key={participant.id}
                className="participant-card"
              >
                <div className="participant-wrapper">
                  <img
                    loading="lazy"
                    src={participant.imageUrl}
                    alt={`Video feed of ${participant.name}`}
                    className="participant-video"
                  />
                  <div className="participant-info">
                    <div className="name-container">
                      <div className="participant-name">{participant.name}</div>
                    </div>
                    <div
                      className="audio-indicator"
                      role="status"
                      aria-label={`Audio ${
                        participant.isAudioActive ? "active" : "active"
                      }`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="main-video">
            <div className="video-container">
              <video
                className="video-feed"
                autoPlay
                muted
                playsInline
                id="live-video"
              />
              <div className="recording-status">
                <div className="record-indicator">
                  <div className="record-icon-wrapper">
                    <div className="record-icon" />
                  </div>
                </div>
                <div className="timer">{formatTime(timeElapsed)}</div>
              </div>
              <div className="user-info">
                <div className="user-name">You</div>
                {/* <div
                  className="audio-status"
                  role="status"
                  aria-label="Audio level indicator"
                /> */}
              </div>
            </div>
          </div>

          <div className="controls-container">
            <div className="controls-wrapper">
              <button
                className="ai-connect-button"
                onClick={
                  isConnected ? disconnectConversation : connectConversation
                }
              >
                {isConnected ? "disconnect" : "connect"}
              </button>
              <button className="stop-video-button" onClick={toggleCamera}>
                {isVideoActive ? "Stop Video" : "Start Video"}
              </button>
         
              {controls.map((control) => (
                <button
                  key={control.id}
                  className={`control-button ${
                    control.isActive ? "active" : ""
                  }`}
                  //  onClick={() => handleControlClick(control.id)}
                  aria-label={control.alt}
                >
                  <img
                    loading="lazy"
                    src={control.src}
                    alt={control.alt}
                    className="control-icon"
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="question-container">
          <div className="header-ai-mock">
            <div className="title-ai-mock">Questions</div>
          </div>
          <div className="questions-list" data-conversation-content>
  {!items.length && `awaiting connection...`}
  {items.map((conversationItem, i) => {
    if (i === 0 && conversationItem.role === "user") {
      return null;
    }

    return (
      <div className="question-item" key={conversationItem.id}>
        <div className={`speaker ${conversationItem.role || ""}`}>
          <div>
            {conversationItem.role === "user" && "User"}
            {conversationItem.role === "assistant" && "Interviewer"}
          </div>
        </div>
        <div className={`speaker-content`}>
          {conversationItem.type === "function_call_output" && (
            <div>{conversationItem.formatted.output}</div>
          )}
          {!!conversationItem.formatted.tool && (
            <div>
              {conversationItem.formatted.tool.name}(
              {conversationItem.formatted.tool.arguments})
            </div>
          )}
          {!conversationItem.formatted.tool &&
            conversationItem.role === "user" && (
              <div>
                {conversationItem.formatted.transcript ||
                  (conversationItem.formatted.audio?.length
                    ? "(awaiting transcript)"
                    : conversationItem.formatted.text ||
                      "(item sent)")}
              </div>
            )}
          {!conversationItem.formatted.tool &&
            conversationItem.role === "assistant" && (
              <div>
                {conversationItem.formatted.transcript ||
                  conversationItem.formatted.text ||
                  "(truncated)"}
              </div>
            )}
        </div>
      </div>
    );
  })}
</div>

        </div>
      </div>
    </>
  );
}
